// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDnyJ97tvNfsdEz5OuOIBakRe2ooyn-B_c",
  authDomain: "gasbillnew1.firebaseapp.com",
  databaseURL: "https://gasbillnew1-default-rtdb.firebaseio.com",
  projectId: "gasbillnew1",
  storageBucket: "gasbillnew1.appspot.com",
  messagingSenderId: "374680791975",
  appId: "1:374680791975:web:9ebe055f0a669045f2e482",
  measurementId: "G-Z8ZDWXKPVE"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };